<template>
  <div class="mb-5">
    <div>
      <h1 class="text-xl text-grey-light font-medium font-headers">
        Results / <span class="font-bold text-grey-dark-1">History</span>
      </h1>
      <div class="flex justify-start mt-3">
        <div class="w-1/4">
          <Select
            :options="assessments"
            :onSelect="setAssessmentType"
            label="Assessment Type"
            :value="selectedAssessment"
            objects
          />
        </div>
      </div>
      <!-- Extract into component -->
      <div class="bg-white shadow mt-3 py-3 px-2 rounded" v-if="selectedComp">
        <h2 class="text-grey-light text-mdl font-bold font-headers">
          Mastery Model Assessments
        </h2>
        <h3 class="ml-2 mt-2 text-mdl font-medium text-grey-dark-1">
          Results and Targets for Competencies
        </h3>
        <div class="flex justify-end">
          <div class="w-1/3">
            <Select
              :options="this.competencies"
              :onSelect="setValue"
              label="Competency"
              :value="selectedComp"
              objects
            />
          </div>
        </div>
        <div class="mt-2">
          <LineBarChart
            :categories="this.competencyData.categories"
            :barData="this.competencyData.barData"
            :lineData="this.competencyData.lineData"
            :barTitle="this.selectedComp.label"
            :barColor="this.selectedComp.color"
            :yAxisMaxValue="yAxisMaxValue"
            :numDivLines="numDivLines"
            :plotSpace="90"
          />
        </div>
        <div class="mt-4 border border-b-0 border-grey-light-1" />
        <h3 class="ml-2 mt-4 text-mdl font-medium text-grey-dark-1">
          Results and Targets for Sub-competencies
        </h3>
        <div class="flex justify-end">
          <div class="w-1/3">
            <Select
              :options="this.competencies"
              :onSelect="setSubcompValue"
              label="Show Sub-competencies for:"
              :value="selectedSubComp"
              objects
            />
          </div>
        </div>
        <div class="mt-5">
          <MultiChart
            :categories="subcompetencyData.categories"
            :barData="subcompetencyData.data"
            :yAxisMaxValue="yAxisMaxValue"
            :numDivLines="numDivLines"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "ResultsHistory",
  data: () => ({
    selectedComp: null,
    selectedSubComp: null,
    categories: [
      { label: "2016" },
      { label: "2017" },
      { label: "2018" },
      { label: "2019" },
      { label: "2020" },
    ],
    selectedAssessment: null,
  }),
  computed: {
    ...mapState({
      assessments: (state) => state.results.parentAssessments,
      assessment: (state) => state.results.assessment,
    }),
    ...mapGetters({
      competencies: "results/getCompetencies",
      getSubcompetencies: "results/getSubcompetencies",
      getCompetencyResult: "results/getCompetencyResult",
      getSubcompetenciesResult: "results/getSubcompetenciesResult",
    }),
    chartData() {
      return this.dataSets[0];
    },
    subCompetencies() {
      if (this.selectedComp) {
        return this.getSubcompetencies(this.selectedComp.id);
      }
    },
    competencyData() {
      if (this.selectedComp) {
        return this.getCompetencyResult(this.selectedComp.id);
      }
    },
    subcompetencyData() {
      if (this.selectedSubComp) {
        return this.getSubcompetenciesResult(this.selectedSubComp.id);
      }
    },
    multiChartData() {
      return this.multiDatasets[0];
    },
    isIndividual() {
      return this.assessment?.surveyType === 0;
    },
    yAxisMaxValue() {
      return this.isIndividual ? "4" : "5";
    },
    numDivLines() {
      return this.isIndividual ? "7" : "9";
    },
  },
  watch: {
    competencies(val) {
      if (val) {
        this.selectedComp = val[0];
        this.selectedSubComp = val[0];
      }
    },
    selectedAssessment() {
      this.getHistoryResults({ id: this.selectedAssessment.id });
      this.getAssessment({ id: this.selectedAssessment.id });
    },
  },
  methods: {
    ...mapActions({
      getHistoryResults: "results/getHistoryResults",
      getParentSurveys: "results/getParentSurveys",
      getAssessment: "results/getAssessment",
    }),
    setValue(val) {
      this.selectedComp = val;
      this.selectedSubComp = val;
    },
    setSubcompValue(val) {
      this.selectedSubComp = val;
    },
    setAssessmentType(val) {
      this.selectedAssessment = val;
    },
  },
  async mounted() {
    await this.getParentSurveys();
    this.setAssessmentType(this.assessments[0]);
    this.getHistoryResults({ id: this.selectedAssessment.id });
  },
};
</script>
